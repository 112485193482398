.toggle-content {
	display: none;
}

.toggle-content.is-visible {
	display: block;
}

.tabulator-row.tabulator-highlighted {
	background-color: #AAC4B7;
}

.tabulator-row.tabulator-row-odd.tabulator-highlighted {
	background-color: #AAC4B7;
}

.tabulator-row.tabulator-row-even.tabulator-highlighted {
	background-color: #AAC4B7;
}